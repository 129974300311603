<template>
  <div class="searchWrapper" @keydown.enter.prevent="searchData">
    <el-form
      :inline="true"
      status-icon
      label-position="right"
      label-width="96px"
      :model="formInline"
      ref="formInline"
      class="demo-form-inline"
    >
      <el-form-item :label="$t('searchModule.park_name')" v-if="searchType == 'historyData'">
        <my-component
          ref="parkInput"
          :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
          :operationId="formInline.operationId"
          @valueChange="completeValue"
          slaveRelations="0,1"
          :parkNameValue="parkNameValue"
        >
        </my-component>
      </el-form-item>
      <el-form-item :label="$t('searchModule.region')" v-else-if="searchType == 'contrast'">
        <el-select v-model.trim="formInline.areaId" filterable placeholder="" size="10">
          <el-option label="全部" value=""></el-option>
          <el-option
            :label="area.areaName"
            :value="area.areaId"
            :key="area.areaId"
            v-for="area in areaList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Vehicle_yard_business_type')" prop="transitionType" v-else>
        <el-select v-model.trim="formInline.transitionType" filterable size="15">
          <el-option label="全部" value=""></el-option>
          <el-option
            :label="item.desc"
            :value="item.code"
            :key="item.code"
            v-for="item in businessTypes"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('searchModule.date')" prop="showDate" style="width: 200px">
        <el-select
          v-model.trim="formInline.showDate"
          filterable
          @change="changeDateType()"
          size="15"
          style="width: 100px"
        >
          <el-option
            label="日"
            value="day"
            v-if="
              searchType != 'analysis' &&
              searchType != 'arrears' &&
              searchType != 'contrast' &&
              searchType != 'historyData'
            "
            >日</el-option
          >
          <el-option label="月" value="month">月</el-option>
          <el-option label="年" value="year">年</el-option>
          <!-- <el-option label="自定义" value="4">自定义</el-option> -->
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="value1"
          type="date"
          :picker-options="pickerOptions"
          placeholder="选择日期"
          v-show="formInline.showDate == 'day'"
        ></el-date-picker>
        <el-date-picker
          v-model="value2"
          type="month"
          :picker-options="pickerOptions"
          placeholder="选择月"
          v-show="formInline.showDate == 'month'"
        ></el-date-picker>
        <el-date-picker
          v-model="value3"
          type="year"
          :picker-options="pickerOptions"
          placeholder="选择年"
          v-show="formInline.showDate == 'year'"
        ></el-date-picker>
        <!-- <span v-show="formInline.showDate == 4">
                <el-input v-model.trim="formInline.startTime" style="width:160px;"></el-input>
                <span>至</span>
      <el-input v-model.trim="formInline.endTime" style="width:160px;"></el-input>
      </span> -->
      </el-form-item>
      <el-button
        type="primary"
        icon="el-icon-search"
        @click="
          pageNum = 1;
          searchData();
        "
        :loading="loading"
        >{{ $t("button.search") }}</el-button
      >
    </el-form>
  </div>
</template>
<script>
import myComponent from "@/components/autocomplete/myautoComponent";
export default {
  props: ["searchType", "isGetTable"],
  data() {
    const newDate = new Date();
    newDate.setTime(newDate.getTime() - 3600 * 1000 * 24);
    return {
      parkNameValue: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > newDate;
        },
      },
      tableData: [],
      areaList: [],
      parkList: [],
      businessTypes: [],
      showDate: 1,
      value1: newDate,
      value2: newDate,
      value3: newDate,
      loading: false,
      formInline: {
        startTime: "",
        endTime: "",
        showDate:
          this.searchType == "analysis" ||
          this.searchType == "arrears" ||
          this.searchType == "contrast" ||
          this.searchType == "historyData"
            ? "month"
            : "day",
        transitionType: "",
        parkId: "",
        areaId: "",
      },
    };
  },
  methods: {
    completeValue(item) {
      this.formInline.parkId = item.parkId;
      this.formInline.parkName = item.parkName;
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        this.areaList = res.value.areaList;
        this.formInline.areaId = "";
      });
    },
    dateType() {
      let dateStr = "";
      if (this.formInline.showDate == "day") {
        dateStr = this.dateFormat(this.value1, "yyyy-MM-dd");
      } else if (this.formInline.showDate == "month") {
        dateStr = this.dateFormat(this.value2, "yyyy-MM");
      } else if (this.formInline.showDate == "year") {
        dateStr = this.dateFormat(this.value3, "yyyy");
      }
      return dateStr;
    },
    getBusinessTypes() {
      this.$axios.get("/acb/2.0/park/businessTypes").then((res) => {
        this.businessTypes = res.value;
      });
    },
    changeDateType() {},
    searchData() {
      if (!this.dateType()) {
        this.$alert("日期不能为空！", this.$t("pop_up.Tips"), {
          confirmButtonText: this.$t("pop_up.Determine"),
        });
        return;
      }
      let url = "";
      if (this.searchType == "regionIncomeStatistical") {
        // 各区收入综合情况
        url = "/acb/2.0/areaStatistic/amount/";
      } else if (this.searchType == "analysis") {
        // 各区收入综合情况 日期明细
        this.getAmountDetail();
        this.getAmount();
        this.getPayRatio();
      } else if (this.searchType == "arrears") {
        // 各区收入综合情况 日期明细
        this.getAmount();
        this.getAmountDetail();
      } else if (this.searchType == "historyData") {
        if (this.formInline.parkId != "") {
          this.$refs.parkInput.setShowVal(this.formInline.parkName);
          // 进出场统计
          this.getEntryExit();
          // 泊位使用统计
          this.getBerthInfo();
        } else {
          this.$alert("请输入并选择停车场", "提示");
          this.$refs.parkInput.setShowVal("");
        }
      } else if (this.searchType == "contrast") {
        this.getContrastData();
      }
      if (url) {
        this.$axios
          .get(url + this.formInline.showDate + "/" + this.dateType(), {
            data: {
              total: 0,
              parkType: this.formInline.transitionType,
            },
          })
          .then((res) => {
            this.$emit("getData", res.value);
            this.$EventBus.$emit("getData", res.value);
          });
      }
    },
    // 获取各区收入明细
    getAmountDetail() {
      this.$axios
        .get(
          "/acb/2.0/areaStatistic/amountDetail/" + this.formInline.showDate + "/" + this.dateType(),
          {
            data: {
              chart: 1,
              parkType: this.formInline.transitionType,
            },
          }
        )
        .then((res) => {
          if (this.searchType == "arrears") {
            this.$EventBus.$emit("getArrearageAmount", res.value);
          } else {
            this.$emit("getData", res.value);
            this.$EventBus.$emit("getData", res.value);
          }
        });
    },
    // 获取实缴排行
    getPayRatio() {
      this.$axios
        .get(
          "/acb/2.0/areaStatistic/payRatio/" + this.formInline.showDate + "/" + this.dateType(),
          {
            data: {
              total: 0,
              parkType: this.formInline.transitionType,
            },
          }
        )
        .then((res) => {
          this.$emit("getPayRatio", res.value);
        });
    },
    // 获取各区收入统计
    getAmount() {
      this.$axios
        .get("/acb/2.0/areaStatistic/amount/" + this.formInline.showDate + "/" + this.dateType(), {
          data: {
            total: 0,
            parkType: this.formInline.transitionType,
          },
        })
        .then((res) => {
          if (!this.isGetTable) {
            for (var i = 0; i < res.value.length; i++) {
              res.value[i].dataDate = this.dateType();
            }
            this.$emit("getData", res.value);
          }
          if (this.searchType == "analysis") {
            this.$EventBus.$emit("getAmount", res.value);
          }
        });
    },
    // 进出场统计
    getEntryExit() {
      this.$axios
        .get(
          "/acb/2.0/parkStatistic/entryExit/" +
            this.formInline.showDate +
            "/" +
            this.dateType() +
            "/" +
            this.formInline.parkId
        )
        .then((res) => {
          this.$EventBus.$emit("getParkStatisticData", res.value);
          this.$emit("getTableData", res.value);
        });
    },
    // 泊位使用统计
    getBerthInfo() {
      this.$axios
        .get(
          "/acb/2.0/parkStatistic/berthInfo/" +
            this.formInline.showDate +
            "/" +
            this.dateType() +
            "/" +
            this.formInline.parkId
        )
        .then((res) => {
          this.$emit("getBerthInfo", res.value);
        });
    },
    // 政府车场商业车场收入对比
    getContrastData() {
      this.$axios
        .get(
          "/acb/2.0/parkStatistic/parkAmountByType/" +
            this.formInline.showDate +
            "/" +
            this.dateType(),
          {
            data: {
              areaId: this.formInline.areaId,
            },
          }
        )
        .then((res) => {
          this.$EventBus.$emit("parkAmountByType", res.value);
        });
    },

    /* 停车场 */
    parkData() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    // 格式化日期
    dateFormat(date, fmt) {
      if (!(date instanceof Date)) {
        return "";
      }
      fmt = fmt || "yyyy-MM-dd HH:mm:ss";
      var o = {
        // 月份
        "M+": date.getMonth() + 1,
        // 日
        "d+": date.getDate(),
        // 小时
        "H+": date.getHours(),
        // 分
        "m+": date.getMinutes(),
        // 秒
        "s+": date.getSeconds(),
        // 季度
        "q+": Math.floor((date.getMonth() + 3) / 3),
        // 毫秒
        S: date.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
  },
  components: {
    myComponent,
  },
  created() {
    this.getBusinessTypes();
    this.parkData();
    this.getArea();
    // this.searchData();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
